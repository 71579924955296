

import {
    IWebsiteOverviewBillboardConfig,
    IWebsiteOverviewConfig,
    IDefaultTableColumnConfig,
    IWebsiteOverviewKpiConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteOverviewConfig implements IWebsiteOverviewConfig {
    public reportTitle = 'Website Overview';
    public useDefaultComparisonColumns = false;
    public benchmarkTitle = 'Retailer Performance';
    public useNewMetricCutoffDate = true;
    public newMetricCutoffDate = new Date(2019, 0, 1);
    public showPieChart: boolean = true;

    public metricDisplayModes = [
      { name: 'POP', value: 'MOM' },
      { name: 'YOY', value: 'YOY' }
    ];

    public chartSeriesColors = ['#4875b4', '#00aced', '#1073af', '#D9D9D9', '#CCCCCC'];
    public deviceTypeColors = ['#4875b4', '#00aced', '#1073af'];
    public referrerTypeColors = ['#c51230', '#9e2a75', '#5f1675', '#6b45cc', '#0f4ec4', '#266886', '#369e8d', '#209645', '#587e35', '#ccc145', '#cc8b45', '#666666'];
    public referrerQualityColors = ['#18b52d', '#224197', '#f9bb11', '#c51230'];
    public referrerQualityCardHeader: ICardHeaderConfig = {
      title: 'Referrer Quality',
      iconClass: 'fa-star',
      helpTextKey: constants.helpTextKeys.websiteOverviewReferrerQualityHelp,
      helpTextTitle: 'Referrer Quality',
    };
    public referrerTypeCardHeader: ICardHeaderConfig = {
      title: 'Referrer Type',
      iconClass: 'fa-align-left',
      helpTextKey: constants.helpTextKeys.websiteOverviewReferrerTypeHelp,
      helpTextTitle: 'Referrer Type',
    };
    public trafficByDeviceTypeHeader: ICardHeaderConfig = {
      title: 'Traffic By Device',
      iconClass: 'fa-mobile',
      helpTextKey: constants.helpTextKeys.websiteOverviewTrafficByDeviceTypeHelp,
      helpTextTitle: 'Traffic By Device',
    };
    public visitorTrendCardHeader: ICardHeaderConfig = {
      title: 'Visitor Trend',
      iconClass: 'fa-bar-chart',
      helpTextKey: constants.helpTextKeys.websiteOverviewVisitorTrendHelp,
      helpTextTitle: 'Visitor Trend',
    };
    public benchmarkCardHeader: ICardHeaderConfig = {
      title: 'Dealer Performance',
      iconClass: 'fa-suitcase',
      helpTextKey: constants.helpTextKeys.websiteOverviewBenchmarkHelp,
      helpTextTitle: 'Benchmark',
      exportName: 'Website Overview - Dealer Performance',
      metricDisplayModes: ['MOM', 'YOY']
    };
    public billboards: IWebsiteOverviewBillboardConfig[] = [
        {
            title: 'Visits',
            subtitle: '',
            iconClass: 'fa-users fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'totalVisits',
            metricMOMPropertyName: 'totalVisitsMOM',
            metricYOYPropertyName: 'totalVisitsYOY',
            metricPreviousMonthPropertyName: 'totalVisitsPreviousMTD',
            metricPreviousMTDPropertyName: 'totalVisitsMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Period',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'POP',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'totalVisitsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewVisitsBillboardHelp,
            helpTextTitle: 'Visits',
            showTrendArrow: true
        },
        {
            title: 'Unique Visitors',
            subtitle: '',
            iconClass: 'fa-user fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'uniqueVisits',
            metricMOMPropertyName: 'uniqueVisitsMOM',
            metricYOYPropertyName: 'uniqueVisitsYOY',
            metricPreviousMonthPropertyName: 'uniqueVisitsPreviousMTD',
            metricPreviousMTDPropertyName: 'uniqueVisitsMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Period',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'POP',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'uniqueVisitsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewUniqueVisitsBillboardHelp,
            helpTextTitle: 'Unique Visitors',
            showTrendArrow: true
        },
        {
            title: 'Engagement',
            subtitle: '',
            iconClass: 'fa-user-plus fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'engagements',
            metricMOMPropertyName: 'engagementsMOM',
            metricYOYPropertyName: 'engagementsYOY',
            metricPreviousMonthPropertyName: 'engagementsPreviousMTD',
            metricPreviousMTDPropertyName: 'engagementsMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Period',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'POP',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'engagementsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewEngagementsBillboardHelp,
            helpTextTitle: 'Engagement',
            showTrendArrow: true
        },
        {
            title: 'Actions',
            subtitle: '',
            iconClass: 'fa-phone fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'actions',
            metricMOMPropertyName: 'actionsMOM',
            metricYOYPropertyName: 'actionsYOY',
            metricPreviousMonthPropertyName: 'actionsPreviousMTD',
            metricPreviousMTDPropertyName: 'actionsMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Period',
            metricPreviousYearPropertyName: null,
            footerRowCount: 1,
            footerTwoLeftLabel: 'POP',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'actionsMOM',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            helpTextKey: constants.helpTextKeys.websiteOverviewActionsBillboardHelp,
            helpTextTitle: 'Actions',
            showTrendArrow: true
        },
    ];

    public kpiSheetOptions = {
      overview: [{
        name: 'name',
        label: '',
        isHeader: true
      }, {
        name: 'visits',
        label: 'Visits',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'vdps',
        label: 'VDPs',
        format: constants.formatKeys.localeString
      }, {
        name: 'engagementRate',
        label: 'Engagement Rate',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'bounceRate',
        label: 'Bounce Rate',
        format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'salesServiceOtherBounces',
        label: 'Sales | Service | Other | Bounces',
      }]
    };

    public kpiCardConfig: IWebsiteOverviewKpiConfig = {
      chartTitle: 'Total Visits vs. Engaged Visits',
      preCutoffChartTitle: 'Total Visits vs. Unique Visits',
      xAxisPropertyName: 'date',
      metrics: {
        'MetricOne': {
            yAxisTitle: 'Visits',
            displayName: 'Total Visits',
            pointLabelFormat: null,
            propertyName: 'totalVisits',
            metricFormatKey: null,
            formatter: null
        },
        'MetricTwo': {
            yAxisTitle: 'Visits',
            displayName: 'Engaged Visits',
            pointLabelFormat: null,
            propertyName: 'visitsWithEngagements',
            metricFormatKey: null,
            formatter: null
        },
        'PreCutoffMetricTwo': {
            yAxisTitle: 'Visits',
            displayName: 'Unique Visits',
            pointLabelFormat: null,
            propertyName: 'uniqueVisits',
            metricFormatKey: null,
            formatter: null
        },
        'PreCutoffMetricOne': {
            yAxisTitle: 'Visits',
            displayName: 'Total Visits',
            pointLabelFormat: null,
            propertyName: 'totalVisits',
            metricFormatKey: null,
            formatter: null
        }
      }
    };

    public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
        {
          show: true,
          header: '',
          columnDef: 'entity',
          hasComparisonMetrics: false,
          metricFormatKey: constants.formatKeys.entityDisplayName,
          clickable: true
        },
        {
          show: false,
          print: true,
          header: 'Dealer Name',
          columnDef: 'displayName',
          hasComparisonMetrics: false,
          metricFormatKey: constants.formatKeys.entityDisplayName
        },

        // Visits
        // {
        //   show: true,
        //   header: 'Visits',
        //   columnDef: 'visits',
        //   hasComparisonMetrics: true,
        //   metricFormatKey: constants.formatKeys.localeString
        // },

        // Page Views
        {
          show: true,
          header: 'Page Views',
          columnDef: 'pageViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          show: false,
          header: 'Prev. Period Page Views',
          columnDef: 'pageViewsPreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Page Views POP',
          columnDef: 'pageViewsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Page Views',
          columnDef: 'pageViewsPreviousYear', isMom: false, isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Page Views YOY',
          columnDef: 'pageViewsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // New VDPs
        {
          show: true,
          header: 'New VDPs',
          columnDef: 'newInventoryDetailViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          show: false,
          header: 'Prev. Period New VDPs',
          columnDef: 'newInventoryDetailViewsPreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'New VDPs POP',
          columnDef: 'newInventoryDetailViewsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year New VDPs',
          columnDef: 'newInventoryDetailViewsPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'New VDPs YOY',
          columnDef: 'newInventoryDetailViewsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Used VDPs
        {
          show: true,
          header: 'Used VDPs',
          columnDef: 'usedInventoryDetailViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          show: false,
          header: 'Prev. Period Used VDPs',
          columnDef: 'usedInventoryDetailViewsPreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Used VDPs POP',
          columnDef: 'usedInventoryDetailViewsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Used VDPs',
          columnDef: 'usedInventoryDetailViewsPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Used VDPs YOY',
          columnDef: 'usedInventoryDetailViewsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // CPO VDPs
        // {
        //   show: true,
        //   header: 'CPO VDPs',
        //   columnDef: 'cpoVdps',
        //   hasComparisonMetrics: true,
        //   metricFormatKey: constants.formatKeys.localeString
        // },

        // Service Views
        {
          show: true,
          header: 'Service Views',
          columnDef: 'servicePageViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        {
          show: false,
          header: 'Prev. Period Service Views',
          columnDef: 'servicePageViewsPreviousMTD',
          isMom: true, isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Service Views POP',
          columnDef: 'servicePageViewsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Service Views',
          columnDef: 'servicePageViewsPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Service Views YOY',
          columnDef: 'servicePageViewsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Engagement Rate
        {
          show: true,
          header: 'Engagement Rate',
          columnDef: 'engagementRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
          show: false,
          header: 'Prev. Period Engagement Rate',
          columnDef: 'engagementRatePreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Engagement Rate POP',
          columnDef: 'engagementRateMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Engagement Rate',
          columnDef: 'engagementRatePreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Engagement Rate YOY',
          columnDef: 'engagementRateYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Action Rate
        // {
        //   show: true,
        //   header: 'Action Rate',
        //   columnDef: 'actionRate',
        //   hasComparisonMetrics: true,
        //   metricFormatKey: constants.formatKeys.percentageTwoDecimals
        // },

        // Avg Time On Site
        {
          show: true,
          header: 'Time On Site',
          columnDef: 'averageTimeOnSite',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
        },
        {
          show: false,
          header: 'Prev. Period Avg. Time On Site',
          columnDef: 'averageTimeOnSitePreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
        },
        {
          show: false,
          header: 'Avg. Time On Site POP',
          columnDef: 'averageTimeOnSiteMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Avg. Time On Site',
          columnDef: 'averageTimeOnSitePreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
        },
        {
          show: false,
          header: 'Avg. Time On Site YOY',
          columnDef: 'averageTimeOnSiteYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Bounce Rate
        {
          show: true,
          header: 'Bounce Rate',
          columnDef: 'bounceRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        {
          show: false,
          header: 'Prev. Period Bounce Rate',
          columnDef: 'bounceRatePreviousMTD',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Bounce Rate POP',
          columnDef: 'bounceRateMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Bounce Rate YOY',
          columnDef: 'bounceRateYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
          show: false,
          header: 'Prev. Year Bounce Rate',
          columnDef: 'bounceRatePreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        }
      ];
}
